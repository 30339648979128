import {
	Groups_Flags_Enum,
	InsertGroups,
	InsertLocationWithGroup,
	Metodologies_Enum,
	Roles_Enum,
} from "src/graphql-operations";

interface ICreateGroup {
	name?: string;
	end_date?: string;
	start_date?: string;
	location_id?: string;
	email?: string;
	methodology?: Metodologies_Enum;
	flag?: Groups_Flags_Enum[] | null;
	content_id?: string;
	url?: string;
	diagnostic_id?: string;
}

interface ICreateLocationAndGroup extends ICreateGroup {
	location_name: string;
	description: string;
	calendar_id: string;
	city: string;
	country: string;
	address: string;
}

interface IsAllowedToAccessPageprops {
	role: Roles_Enum;
	acceptedRoles: Roles_Enum[];
}

export const createGroup = async (groupData: ICreateGroup) => {
	await InsertGroups({
		variables: groupData,
		refetchQueries: ["GetGroups"],
	});
};

export const createLocationAndGroup = async (
	locationData: ICreateLocationAndGroup,
) => {
	await InsertLocationWithGroup({
		variables: locationData,
		refetchQueries: ["GetGroups", "GetLocations"],
	});
};

export const isAllowedToAccess = ({
	acceptedRoles,
	role,
}: IsAllowedToAccessPageprops) => {
	return acceptedRoles.includes(role);
};
